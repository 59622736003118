<template>
	<div class="app-main__inner">
		<faq-component :key="new Date().getMinutes()" :section="`partner-accountant`"/>
	</div>
</template>

<script>

import FaqComponent from "../Components/FaqComponent";

export default {
	name: 'faq-section',
	components: {
		FaqComponent
	},
}
</script>
